#frågorOchSvarHead{
  text-align: center;
  margin-top: 80px;
  margin-bottom: 80px;
}
.frågorOchSvar{
  font-size: 25px;
  color: white;
}
#fråga{
  margin-left: 40px;
  width: 800px;
  background-color: rgb(138, 133, 133);
  padding: 20px;
  text-align: center;
  border-radius: 50px 50px 50px 5px;
  margin-top: 190px;
}
#svar{
  float: right;
  margin-right: 40px;
  width: 800px;
  background-color: rgb(23, 23, 253);
  padding: 20px;
  text-align: center;
  border-radius: 50px 50px 5px;
}
#mail{
  color: white;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  background-color: #f2f2f2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;

}


.header {
  margin: 0;
  margin-right: 50px;
}

.navbar a{
  font-size: 34px;
  color: black;
  text-decoration: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

#bodyTitle {
  margin-top: 30px;
  font-size: 24px;
  text-align: center;
  color: #333;
}

.foton {
  background-color: #4CAF50;
  width: 80%;
  margin: auto;
  border: 5px solid #ddd;
  border-radius: 10px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  padding: 10px;
}

.foton img {
  height: 300px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 10px 4px 8px rgba(0, 0, 0, 0.1);
}

#betaKnapp {
  margin-top: 20px;
  margin-left: 30px;
  font-size: 18px;
  text-align: center;
  background-color: #4CAF50;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}


#betaKnapp:hover {
  background-color: #45a049;
}

#info {
  text-align: center;
  margin-top: 40px;
  color: #555;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .foton {
      width: 100%;
  }

  #betaKnapp {
      width: 100%;
  }
}


.about h1{
  margin-top: 80px;
  text-align: center;
}

.about ul{
  font-size: 20px;
  margin-bottom: 40px;

}

#OBS{
  font-weight: bold;
}

.navbar {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 20px;
  margin: 0;
  height: 70px;
}

#main{
  font-weight: bold;
  border-right: 2px solid black;
  padding-right: 50px;
}

.mail{
  margin-left: 40px;
  margin-top: 40px;
}

.mail a{
  color: black;
  font-weight: bold;

}

.mail p{
  font-weight: bold;
}


#mailorm{
  margin-top: 60px;
}

.supportHead{

  text-align: center;
  margin-top: 50px;

}

.material{
  margin-top: 80px;
  width: 260px;
  height: 200px;
  position: relative;
  background-color: white;
  margin-left: 50px;
  box-shadow: 2px 2px 5px 2px;
  border-radius: 10px;
}


.material p{
  font-size: 20px;
  text-decoration: underline;
  text-align: center;
  margin-bottom: 40px;
  margin-top: 40px;
  
}

.material a{
  background-color: #4CAF50;
  color: white;
  padding: 15px;
  border-radius: 10px;
  text-decoration: none;
  display: block;
  margin: auto;
  width: 160px;

}

.material a:hover {
  background-color: #45a049;
}
.navbar {
  display: flex;
  justify-content: space-between; /* Separerar huvudnavigeringen och kontonavigeringen */
  align-items: center;
  background-color: #ffffff;
  padding: 20px;
  height: 70px;
}

.nav-links, .nav-account {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.nav-links li, .nav-account li {
  margin-right: 20px;
}
.nav-account {
  margin-left: auto; /* Flyttar kontodelen till höger */
}

#mittKonto {
  font-weight: bold;
}

.pdfs{

  text-align: center;
  display: flex;
  margin-bottom: 60px;

}

@media (max-width: 980px) {
  .pdfs {
    flex-direction: column;
    align-items: center;
  }

  .pdfs div {
    margin-right: 0;
    margin-bottom: 20px;
  }
}





.navbar ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: space-around;
}

.navbar ul li {
  margin-right: 20px;
}

.navbar ul li:last-child {
  margin-right: 0;
}

.navbar a.active {
  text-decoration: underline;
}

#kebbe{
  font-family: Arial;
  width: 150px;
  
}

.active text {
  text-decoration: underline;
}


#frågorOchSvarHead{
  text-align: center;
  margin-top: 80px;
  margin-bottom: 80px;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 20px;
  height: 70px;
}

.nav-links, .nav-account {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.nav-links li, .nav-account li {
  margin-right: 20px;
}

.nav-account {
  margin-left: auto;
}

#mittKonto {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* Viktigt för att placera tooltipen */
  transition: all 0.3s ease;
}

#mittKonto.active {
  border: 2px solid black;
  width: 70px;
  height: 70px;
}

#mittKonto:hover::after {
  content: "My Account"; /* Texten som visas vid hover */
  position: absolute;
  top: 80px; /* Justerar var tooltipen visas */
  left: 50%;
  transform: translateX(-50%); /* Centrerar texten */
  background-color: #333;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap; /* Gör så att texten inte bryts */
  font-size: 14px;
  opacity: 0;
  transition: opacity 0.2s ease;
  opacity: 1;
}




.konto-ikon {
  width: 30px; /* Anpassa ikonens storlek */
  height: 30px;
  border-radius: 50%; /* Gör ikonen rund om det behövs */
}
#mail{
  color: white;
}

#omOssHead{
  text-align: center;
}

#bodyOmOss{
  padding: 100px;
  font-size: 20px;
}

.users-container {
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
}

.user-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-icon {
  width: 100px;
  height: 100px;
  background-color: #ccc;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  color: white;
  margin-bottom: 10px;
}

.user-info {
  text-align: center;
}

.user-name {
  font-size: 18px;
  font-weight: bold;
}

.user-role {
  font-size: 14px;
  color: #666;
}
