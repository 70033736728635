
@media print {
    @page {
      size: A4 portrait;

    }

    * {
      max-height: 700vh; /* Begränsa höjden till skärmens synliga höjd */
      margin: 0; /* Ta bort marginaler för att utnyttja hela sidan */
  }

    .no-page-break {
      page-break-after: avoid;
      page-break-before: avoid;
      page-break-inside: avoid;
  }
}