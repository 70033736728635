@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities'; 


#uppe {
  border-bottom: solid;
  font-size: 20px;
  width: 44px;
  margin: auto;
  margin-bottom: 20px;
}

#nere {
  border-top: solid;
  font-size: 20px;
  width: 30px;
  margin: auto;
  margin-top: 20px;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    top: 0;
  }

  

  .inputSection {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: fit-content;
    width: 100%;
  }

  .namnFält {
    margin-right: 0;
    padding: 0;
  }
  

 
  
  
  
  .addButton {
    font-weight: bolder;
    font-size: 20px;
    width: 300px;
    height: 200px;
    background-color: #38b438;
    color: white;
    margin: 0;
    border-radius: 0 20px 20px 0;
    border: 1px solid black;
    border-width: 1px 1px 1px 1px;
  }
  
button{
    margin: 0;
}
  .excelSection {
    margin-left: 15px;
  }

  .nameList {
    display: flex;
    justify-content: flex-start;
  }
  
  .column {
    flex: 1;
  }
  input{
    color: black;
  border-color: rgb(34, 172, 16);
  border-style: solid;
  padding: 3px;
  font-size: 20px;
  border-radius: 4px;
  margin: 10px;
  width: 130px;
  cursor: pointer;
  }
  .excelSection input{
    font-weight: bolder;
    font-size: 20px;
    width: 100%;
    border-radius: 11px;
    margin-left: 0;
  }

#efternamnStarForst {
    display: none; 
}

.custom-checkbox {
    display: inline-block;
    border-radius: 0; 
    background-color: #f0f0f0; 
    border: 1px solid black; 
    border-width: 0 1px 1px 1px;
    position: relative; 
    cursor: pointer;
    width: 100%;
    height: 111px;

}

#knappar{
  margin: 0;
  top: -40px;
  height: auto;
  position: relative;
}
#efternamnStarForst:checked + .custom-checkbox {
    background-color: #4CAF50; 
    border-color: black; 
}

label{
  padding: 0;
  width: 100%;
  height: auto;
}
#efternamnStarForst:checked + .custom-checkbox::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg); /* Center and rotate */
    width: 10px;
    height: 20px;
    border: solid white; 
    border-width: 0 5px 5px 0;
}

button{
    user-select: none;
}
#sparaNamnKnapp2{
    font-weight: bold;
    border-radius: 0 5px 0 0;
    margin-left: 0px;
    border: 1px solid black;
    width: 100%;
}
#chek{
  height: 70px;
}
#checklabel{
  background-color: #4CAF50; 
  color: white;
  font-size: small;
  font-weight: bold;
  height: 70px;
  border: solid black;
}
#checklabel span{
  margin: auto;
}
/* Hide the default file input */
input[type="file"] {
  display: none;
}

/* Style the custom button */
.custom-file-upload {
  display: inline-block;
  padding: 10px 20px;
  background-color: #38b438;
  color: white;
  border: 1px solid black;
  border-radius: 0 0 5px 0;

  cursor: pointer;
  font-size: 16px;
}

/* Add hover effects for the custom button */
.custom-file-upload:hover {
  background-color: #38b438;
}

.file-input-container {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 599px;
}

#file-name {
  font-size: 14px;
  color: #666;
}

label{
  text-align: center;
  font-weight: bold;
  width: 598px;
  font-size: medium;
}

/* Hover effect for desktop only */
/*@media only screen and (min-width: 1025px) {
  li:hover {
    background-color: lightgray;
  }
}*/
ul{
  border-radius: 5px;
}

/*li{
  border-radius: 5px;
}*/
.nameList {
  display: flex;
  justify-content: center; /* Horizontally center the content */
  margin: auto;            /* Center within the container */
  padding: 10px;
}
.custom-button {

  background-color: #38b438;
  color: white;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0; 
  border: 1px solid black;
  border-width: 1px 0 1px 1px;


}

.custom-button:hover {
  opacity: 0.9;
}

.custom-button:active {
  opacity: 0.8;
  text-decoration: none;
}

.ejEfternamn{
  border: 1px solid black;
}